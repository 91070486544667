import PropTypes from 'prop-types';
import Link from 'next/link';

import { formatSlug } from 'utils/article-helpers';
import { buildCssVar } from 'utils/style-override';
import { useTextFontOverrides, useHeadingFontOverrides } from 'utils/font-override';
import formatDate from 'utils/format-article-date';
import { useFunnelData } from 'utils/funnel-data-context';

import Image from './Image';

import styles from './ArticleComponent.module.css';
import { useGetPathWithQueryParams } from 'utils/article-link';

const propTypes = {
  id: PropTypes.string.isRequired,
  coverImage: PropTypes.object,
  mainHeading: PropTypes.string,
  publishDate: PropTypes.string,
  tagBackgroundColor: PropTypes.string,
  tagTextColor: PropTypes.string,
  isSingleChild: PropTypes.bool,
  headingFont: PropTypes.shape({ family: PropTypes.string }),
  textFont: PropTypes.shape({ family: PropTypes.string }),
  layout: PropTypes.string,
  tag: PropTypes.shape({ tag: PropTypes.string }),
};

const defaultProps = {
  category: null,
  path: null,
  coverImage: null,
  mainHeading: null,
  publishDate: null,
  tagBackgroundColor: null,
  tagTextColor: null,
  isSingleChild: false,
  headingFont: null,
  textFont: null,
  layout: 'layout1',
  tag: null,
  imageWidth: null,
  imageHeight: null,
  imageSizes: null,
  imagePriority: null,
  imageFill: null,
  imageLoading: null,
};

export default function ArticleComponent({
  category,
  path,
  coverImage,
  mainHeading,
  publishDate,
  tagBackgroundColor,
  tagTextColor,
  isSingleChild,
  headingFont,
  textFont,
  layout,
  tag,
  imageWidth,
  imageHeight,
  imageSizes,
  imagePriority,
  imageFill,
  // imageLoading,
}) {
  const headingFontOverrides = useHeadingFontOverrides(headingFont?.family);
  const textFontOverrides = useTextFontOverrides(textFont?.family);

  const { funnelId } = useFunnelData();
  const url = `/${formatSlug(category?.name)}/${path}`;
  const fullPath = useGetPathWithQueryParams(url);

  return (
    <>
      <div
        className={`root ${styles.container}  ${
          isSingleChild ? styles.full_width : styles.half_width
        }`}
      >
        <style jsx>{`
          .root {
            ${buildCssVar(
              '--tag-background-color',
              tagBackgroundColor,
              'var(--background-color-grey)'
            )}
            ${buildCssVar('--tag-text-color', tagTextColor, 'var(--text-color-dark)')}
          ${headingFontOverrides ?? ''}
          ${textFontOverrides ?? ''}
          }
        `}</style>

        <div className={`${styles.wrapper}  ${styles[layout]}`}>
          <div className={`${styles.img_wrapper}`}>
            {coverImage && (
              <Link className={styles.article_btn} href={fullPath}>
                <div className={styles.img}>
                  <Image
                    src={coverImage}
                    alt={mainHeading}
                    placeholder="blur"
                    height={imageHeight}
                    width={imageWidth}
                    sizes={imageSizes}
                    priority={imagePriority}
                    fill={imageFill}
                    // loading={imageLoading}
                  />
                </div>
              </Link>
            )}
          </div>
          <div className={styles.text_wrapper}>
            <div className={styles.header}>
              <Link href={`/category/articles/${formatSlug(tag.name)}?fid=${funnelId}`} passHref>
                <p className={styles.tag}>{tag.name}</p>
              </Link>
              <p className={styles.header_divider}>&#x2022;</p>
              {publishDate && <p className={styles.date}>{formatDate(publishDate)}</p>}
            </div>
            <Link className={styles.article_btn} href={fullPath}>
              <h1 className={styles.heading_underline}>{mainHeading}</h1>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

ArticleComponent.propTypes = propTypes;
ArticleComponent.defaultProps = defaultProps;
