import React from 'react';
import Link from 'next/dist/client/link';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';

import Image from './Image';

import { useFunnelData } from 'utils/funnel-data-context';
import { buildCssVar } from 'utils/style-override';
import { useTextFontOverrides, useHeadingFontOverrides } from 'utils/font-override';

import styles from './ProductCard.module.css';
import { addQueryParamsToUrl } from 'utils/article-link';

const MarkdownText = dynamic(() => import('./MarkdownText'));

const propTpes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.object,
  imageAlt: PropTypes.string,
  buttonText: PropTypes.string,
  titleColor: PropTypes.string,
  textColor: PropTypes.string,
  buttonColor: PropTypes.string,
  buttonTextColor: PropTypes.string,
  buttonBorderColor: PropTypes.string,
  buttonOnHoverColor: PropTypes.string,
  buttonOnHoverTextColor: PropTypes.string,
  buttonOnHoverBorderColor: PropTypes.string,
  textFont: PropTypes.object,
  headingFont: PropTypes.object,
  layout: PropTypes.string,
};

const defaultProps = {
  title: null,
  description: null,
  image: null,
  imageAlt: null,
  buttonText: null,
  titleColor: null,
  textColor: null,
  buttonColor: null,
  buttonTextColor: null,
  buttonBorderColor: null,
  buttonOnHoverColor: null,
  buttonOnHoverTextColor: null,
  buttonOnHoverBorderColor: null,
  textFont: null,
  headingFont: null,
  layout: 'layout1',
  imageHeight: null,
  imageWidth: null,
};

function ProductCard({
  title,
  description,
  image,
  imageAlt,
  imageHeight,
  imageWidth,
  buttonText,
  id,
  titleColor,
  textColor,
  buttonColor,
  buttonTextColor,
  buttonBorderColor,
  buttonOnHoverColor,
  buttonOnHoverTextColor,
  buttonOnHoverBorderColor,
  textFont,
  headingFont,
  layout,
}) {
  const { linkedPagesMap } = useFunnelData();
  const linkedPage = linkedPagesMap[`${id}`];
  const price = linkedPage?.price;
  const productName = linkedPage?.productName;
  const url = linkedPage?.url;

  const textFontOverrides = useTextFontOverrides(textFont?.family);
  const headingFontOverrides = useHeadingFontOverrides(headingFont?.family);

  const router = useRouter();

  return (
    <div className={`root ${styles.container} ${styles[layout]}`}>
      <style jsx>{`
        .root {
          ${buildCssVar('--text-color', textColor, 'var(--text-color-dark)')}
          ${buildCssVar('--heading-color', titleColor, 'var(--heading-color-dark)')}
          ${buildCssVar('--button-color', buttonColor, '#121212')}
          ${buildCssVar('--button-text-color', buttonTextColor, 'var(--text-color-light)')}
          ${buildCssVar('--button-border-color', buttonBorderColor, '#121212')}
          ${buildCssVar('--button-on-hover-color', buttonOnHoverColor, 'transparent')}
          ${buildCssVar(
            '--button-on-hover-text-color',
            buttonOnHoverTextColor,
            'var(--text-color-dark)'
          )}
          ${buildCssVar('--button-on-hover-border-color', buttonOnHoverBorderColor, '#121212')}

          ${headingFontOverrides ?? ''}
          ${textFontOverrides ?? ''}
        }
      `}</style>

      <h1 className={styles.heading}>{title}</h1>
      <div className={styles.img_name_wrapper}>
        <div className={styles.img_wrapper}>
          {image && (
            <Image
              src={image}
              alt={imageAlt}
              placeholder="blur"
              width={imageWidth}
              height={imageHeight}
              sizes={imageWidth}
            />
          )}
        </div>

        {productName && price && (
          <div className={styles.price_product_wrapper}>
            <p className={styles.product}>{productName}</p>
            <p className={styles.price}>${price}</p>
            {url && (
              <Link href={url} legacyBehavior>
                <a target="_blank">
                  <div className={styles.btn}>{buttonText}</div>
                </a>
              </Link>
            )}
          </div>
        )}
      </div>

      <div className={styles.text_wrapper}>
        {description && <MarkdownText text={description} />}
      </div>

      <div className={styles.btn_wrapper}>
        {url && (
          <Link href={addQueryParamsToUrl(url, router.query)} legacyBehavior>
            <a target="_blank">
              <div className={styles.btn}>{buttonText}</div>
            </a>
          </Link>
        )}
      </div>
    </div>
  );
}

ProductCard.propTypes = propTpes;
ProductCard.defaultProps = defaultProps;

export default ProductCard;
